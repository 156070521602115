<template>
	<div>
		<h3>Try resetting your password again</h3>
		<p>Your request to reset your password has expired or the link has already been used.</p>
		<b-button variant="link" class="px-0" v-b-modal.forgot-password>Send Reset Password?</b-button><br/>
		<b-link to="/Login">Go back to Ayun!</b-link>
		<ForgotPassword/>
	</div>
	
</template>
<script>
import ForgotPassword from './ForgotPassword';
export default {
	name: 'InvalidCode',
	components:{
		ForgotPassword
	}
};
</script>